import { Link, SxProps } from '@mui/material';

import { dataLayerConfig, IDataLayerEventConfig, replaceUrlContentVars } from '@/utils';
import { incorrectUsageWarning } from '@/utils/miscUtils';
import { Maybe, AnalyticsContext } from '@/types/generated';
import { useAppContext } from '@/context/AppContextProvider.ctx';
import { useFacilityContext } from '@/context/FacilityContextProvider.ctx';
import { useOfficeLocationContext } from '@/context/OfficeLocationsContextProvider.ctx';

import NextLinkComposed, { DISPLAY_NAME as DISPLAY_NAME_NEXT } from './NextLinkComposed';
import { getHrefPath, getUrlType, handleAnchorLinkClick } from './TAGLink.helpers';

interface ITAGLink {
	/**
	 * Contains text representing advisory information related to the element it belongs to. Good for assistive technology.
	 *
	 * @type {string}
	 * @memberof ITAGLink
	 */
	title?: string;
	/**
	 * Callback function to be executed when the link is clicked.
	 *
	 * @memberof ITAGLink
	 */
	onClick?: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
	children: React.ReactNode;
	/**
	 * Link for the anchor tag. Can be internal, external, or a hash (anchor link).
	 *
	 * @type {string}
	 * @memberof ITAGLink
	 */
	href?: string;
	/**
	 * When link is external, this prop will decide whether to open the link in a new tab or not.
	 *
	 * @type {boolean}
	 * @memberof ITAGLink
	 */
	openInNewTab?: boolean;
	/**
	 * When true, the link will not have any styles applied to it.
	 *
	 * @type {boolean}
	 * @memberof ITAGLink
	 */
	noLinkStyle?: boolean;
	/**
	 * Wrapper component to be used for the link. Can be a Next.js Link or a Material UI Link.
	 * Next.js adapter is used by default, for itnernal and anchor links. For external, Material UI Link is used.
	 *
	 * @type {((typeof Link | typeof NextLinkComposed) & { displayName?: string })}
	 * @memberof ITAGLink
	 */
	wrapper?: (typeof Link | typeof NextLinkComposed) & { displayName?: string };
	/**
	 * Optional data-testid attribute for the link. If not passed, the default data-test-id will be used.
	 *
	 * @type {string}
	 * @memberof ITAGLink
	 */
	dataTestId?: string;
	linkText?: string;
	analyticsContext?: Maybe<AnalyticsContext>;
	/**
	 * Optional sx props for the MUI Link component.
	 *
	 * @type {SxProps}
	 * @memberof ITAGLink
	 */
	linkSx?: SxProps;
	/**
	 * Optional className prop to add a class.
	 *
	 * @type string
	 * @memberof ITAGLink
	 */
	className?: string;
	schedulingDataLayer?: IDataLayerEventConfig;
}

function isNextLink(component: ITAGLink['wrapper']): component is typeof NextLinkComposed {
	return (component as typeof NextLinkComposed).displayName === DISPLAY_NAME_NEXT;
}

export default function TAGLink({
	title = '',
	linkSx,
	onClick,
	children,
	href = '#',
	dataTestId,
	analyticsContext,
	noLinkStyle = false,
	openInNewTab = false,
	wrapper: Wrapper = NextLinkComposed,
	className,
	schedulingDataLayer,
}: ITAGLink) {
	function handleClick(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
		const { isAnchorUrl } = getUrlType(href);
		if (schedulingDataLayer) {
			dataLayerConfig(schedulingDataLayer);
		}

		// temporarily going back to directly using the dataLayerConfig due to InfoTrust stonewalling necessary custom event changes in GTM
		if (analyticsContext && analyticsContext.event) {
			dataLayerConfig(analyticsContext);
		}

		dataLayerConfig({
			event: 'navigation_click_event',
			brand: config.name,
			page_Destination_url: hrefPath,
			link_click_location: analyticsContext?.clickPosition || 'page',
			website_selected_facility_id: officeInfo?.code || '',
			website_selected_facility_name: officeInfo?.name || '',
		});

		if (isAnchorUrl) {
			handleAnchorLinkClick(href);
		}

		if (onClick) {
			onClick(event);
		}
	}

	const { config } = useAppContext();
	const { officeInfo } = useFacilityContext();
	const { officeInfo: officeLocationInfo } = useOfficeLocationContext();

	const migrationPaths = config.features.migration.paths;

	href = replaceUrlContentVars(href, officeInfo, officeLocationInfo, config);

	const hrefPath = getHrefPath(href.toString(), migrationPaths);

	const isTelLink = href?.startsWith('tel:');
	const updatedClassName = isTelLink ? (className ? `${className} InvocaNumber` : 'InvocaNumber') : className;

	if (isNextLink(Wrapper)) {
		return (
			<Wrapper
				href={hrefPath}
				title={title}
				onClick={handleClick}
				openInNewTab={openInNewTab}
				noLinkStyle={noLinkStyle}
				dataTestId={dataTestId}
				linkSx={linkSx}
				className={updatedClassName}
			>
				{children}
			</Wrapper>
		);
	}

	incorrectUsageWarning('TAGLink', 'unknown wrapper type passed to TAGLink');

	return <>{children}</>;
}
